import React, { useState, useEffect } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Navbar from '../components/navbar'

import { useStaticQuery, graphql, Link } from 'gatsby'

import { FaTrashAlt } from "react-icons/fa";

const Decolonizing = ({ location }) => {

    const data = useStaticQuery(graphql`
        query {
            allContentfulLesson {
                nodes {
                    title
                    body {
                        body
                    }
                    images {
                        fluid {
                            src
                        }
                    }
                    contributor {
                        name
                        image {
                            fluid {
                                src
                            }
                        }
                    }
                    subjectTags
                    type
                    timescale
                    slug
                }
            }
        }
    `)

    const [ showArenaNotice, setShowArenaNotice ] = useState(true)

    const [ isMobile, setIsMobile ] = useState(false)

    useEffect(() => {
        location.state && location.state.fromLesson && setShowArenaNotice(false)
        typeof window !== `undefined` && window.innerWidth < 900 && setIsMobile(true)
    }, [location.state])

    const allLessons = data.allContentfulLesson.nodes

    // generate dropdown of filters
    const typeFilters = []

    allLessons.map(({ type }) => 
        type && type.forEach((value) => {
            if(!typeFilters.includes(value)){
                typeFilters.push(value)
            }
        })
    )

    const typeList = typeFilters.map((type, index) => 
        <button key={index} 
            onClick={() => { setShowType(false); setFilterByType(type)}}
            className="is-text hide-default-btn-styles has-text-centered is-size-4 is-size-3-mobile"
        >
            {type}
        </button>
    )

    // filter lessons by type selection
    const [ filterByType, setFilterByType ] = useState(null)
    const [ showType, setShowType ] = useState(false)

    let lessons;
    if(filterByType){
        lessons = allLessons.filter((lesson) => lesson.type && lesson.type.includes(filterByType))
    } else {
        lessons = allLessons
    }

    // filter lessons by timescale selection
    const all = 'All'
    const histories = 'Art Histories'
    const futures = 'Art Futures'
    const [ selectedTimescale, setSelectedTimescale] = useState(all)

    const changeTimescale = (timescale) => {
        //set active btn
        const prevSelectedTimescale = document.getElementsByClassName('is-active')[0]
        prevSelectedTimescale && prevSelectedTimescale.classList.remove('is-active')

        //add the underline to the currently set timescale
        document.getElementById(timescale).className += " is-active"

        //change the timescale state value
        setSelectedTimescale(timescale)
    }

    if(selectedTimescale !== all){
        lessons = lessons.filter(({ timescale }) => timescale && timescale === selectedTimescale)
    }

    //reveal no more than maxNumOfLessons at a time
    const lessonCount = lessons.length
    const maxNumOfLessons = 9
    const [ minLessonIndex, setLessonIndex ] = useState(0)
    const remainingLessons = lessonCount - minLessonIndex
    const numOfLessonNodes = remainingLessons > maxNumOfLessons ? maxNumOfLessons : remainingLessons
    const maxLessonIndex = minLessonIndex + numOfLessonNodes

    // place lessons (represented as circular nodes) along the courtyard perimeter

    const [ lessonName, setLessonName ] = useState('')

    const radius = isMobile ? '150px' : '250px' //courtyard diameter set in CSS

    const positionNode = (index) => {
        let angle = 360/maxNumOfLessons
        if(index === 0){
            return `translate(${radius})`
        } else if(index === maxNumOfLessons/2){
            return `translate(-${radius})`
        } else {
            return `rotate(${index * angle}deg) translate(${radius}) rotate(-${index * angle}deg)`
        }
    }

    let newLessonsBtn;
    if(maxLessonIndex === lessonCount && lessonCount > maxNumOfLessons){
        newLessonsBtn = <button className="button is-large spin-btn" onClick={() => setLessonIndex(0)}>
                            RESTART
                        </button>
    } else if(maxLessonIndex === lessonCount && lessonCount < maxNumOfLessons){
        newLessonsBtn = <p>More lessons will be added soon :)</p>
    } else {
        newLessonsBtn = <>
                            <button className="button is-large spin-btn" onClick={() => setLessonIndex(maxLessonIndex)}>
                                SPIN
                            </button>
                            <p>to reveal new lessons</p>
                        </>
    }

    // filter by contributor

    const [ showContributors, setShowContributors ] = useState(false)
    const [ filterByContributor, setFilterByContributor ] = useState(null)

    const allContributors = []
    allLessons.map(({ contributor }) => 
        contributor && contributor.forEach(({name}) => {
            if(!allContributors.includes(name)){
                allContributors.push(name)
            }
        })
    )

    const contributorList = allContributors.map((contributorName, index) => 
        <button key={index} 
            onClick={() => { setShowContributors(false); setFilterByContributor(contributorName)}}
            className="hide-default-btn-styles has-text-centered is-size-4 is-size-3-mobile"
        >
            {contributorName}
        </button>
    )

    if(filterByContributor){
        lessons = lessons.filter(({ contributor }) => {
            let result = contributor && contributor.map(c => c.name)
            return result ? result.includes(filterByContributor) : false //if there are no contributors return false to filter
        })
    }

    //filter by subject tags
    const [ showTags, setShowTags ] = useState(false)
    const [ filterByTag, setFilterByTag ] = useState(null)

    const allTags = []
    allLessons.map(({ subjectTags }) => 
        subjectTags && subjectTags.split(', ').forEach((tag) => {
            if(!allTags.includes(tag)){
                allTags.push(tag)
            }
        })
    )

    const tagList = allTags.map((tag, index) => 
        <button key={index}
            onClick={() => { setShowTags(false); setFilterByTag(tag)}}
            className="is-text hide-default-btn-styles is-size-4 is-size-3-mobile"
        >
            {tag}
        </button>
    )

    if(filterByTag){
        lessons = lessons.filter(({subjectTags}) => subjectTags && subjectTags.includes(filterByTag))
    }

    const checkBgImg = (images) => {
        if(images[0].fluid){
            return images[0].fluid.src
        } else {
            for(let i=1; i < images.length; i++){
                if(images[i].fluid){
                    return images[i].fluid.src;
                }
            }
        }
    }

    const clearFilters = () => {
        setFilterByType('')
        setFilterByContributor(null)
        setFilterByTag(null)
    }

    const sectionClass = isMobile ? '' : 'section'
    const sectionPaddingTop = showArenaNotice ? 'pt-0' : ''

    return (
        <Layout>

            <SEO title="Decolonize the Art World" />

            <Navbar />

            <div className="hero-head imaginarium-header">
                <div className="container">
                    <h1 className="title pt-3 pb-3 imaginarium-title is-size-2 is-size-3-mobile has-text-centered">
                        DECOLONIZE THE ART WORLD
                    </h1>
                </div>
            </div>

            <section className={`${sectionClass} ${sectionPaddingTop} pb-3 gradient decolonize-gradient-bg`}>

                {
                    showArenaNotice &&
                        <div className="columns is-full is-vcentered arena-notice mb-6">
                            <p className="column is-9 has-text-centered">
                                All sources referenced are available on the {` `}
                                <a href="http://decolonizetheart.world" target="_blank" rel="noopener noreferrer">
                                    Decolonizing the Art World Are.na page
                                </a>
                            </p>
                            <p className="column is-3 has-text-right has-text-weight-bold has-text-left-mobile">
                                <button onClick={() => setShowArenaNotice(false)} className="button is-text is-size-5">
                                    Dismiss
                                </button>
                            </p>
                        </div>
                }

                <section className={`${sectionClass} pt-5 pb-0`}>
                    <div className="columns is-vcentered mb-6" style={{marginLeft: '1.5rem'}}>
                        {/* RIGHT SIDE CONTAINING COURTYARD */}
                        <div className="column is-two-thirds courtyard-mobile">
                            <div className="courtyard">
                                {
                                    lessons.slice(minLessonIndex, maxLessonIndex).map(({slug, title, images}, index) =>
                                        <Link key={index} to={`decolonize-the-art-world/lesson/${slug}`}>
                                            <div className="node" 
                                                style={{
                                                    transform: positionNode(index),
                                                    backgroundImage: `url(${checkBgImg(images)})`
                                                }}
                                                onMouseOver={() => setLessonName(title)}
                                                onFocus={() => setLessonName(title)}
                                                onMouseOut={() => setLessonName('')}
                                                onBlur={() => setLessonName('')}
                                                role="button"
                                                tabIndex={0}
                                            >
                                            </div>
                                        </Link>
                                    )
                                }
                                <p className="has-text-centered lesson-name is-size-4">
                                    {lessonName}
                                </p>
                            </div>
                        </div>
                        {/* LEFT SIDE CONTAINING TEXT */}
                        <div className="column content is-one-third has-text-left has-text-justified-mobile is-size-4">
                            <p className="is-size-5 is-size-4-mobile">Choose timescale:</p>
                            <div className="columns timescale-select">
                                <button className="column button is-text is-size-5 is-active" id={all} onClick={() => changeTimescale(all)}>
                                    All
                                </button>
                                <button className="column button is-text is-size-5" id={histories} onClick={() => { changeTimescale(histories); setLessonIndex(0)}}>
                                    Art Histories
                                </button>
                                <button className="column button is-text is-size-5" id={futures} onClick={() => { changeTimescale(futures); setLessonIndex(0)}}>
                                    Art Futures
                                </button>
                            </div>
                            <div className="mt-6">
                                <div className="is-size-6 mb-3">
                                    <p className="has-text-weight-bold">Active filters</p>
                                    <p>
                                        Type: <span className="active-filter">{filterByType} </span> 
                                        Contributor: <span className="active-filter">{filterByContributor} </span> 
                                        Tag: <span className="active-filter">{filterByTag} </span>
                                    </p>
                                    <button className="button is-text is-size-6" onClick={() => clearFilters()}>
                                        Clear Filters <FaTrashAlt className="ml-3" />
                                    </button>
                                </div>
                                <p className="is-size-5">Filter lessons by:</p>
                                <div className="columns content filter-btns-mobile">
                                    <button className='column button is-primary is-medium mr-3'
                                        onClick={() => setShowType(true)}>
                                            type
                                    </button>
                                    <button className='column button is-primary is-medium mr-3' 
                                        onClick={() => setShowContributors(true)}>
                                            contributors
                                    </button>
                                    <button className='column button is-primary is-medium'
                                        onClick={() => setShowTags(true)}>
                                            tags
                                    </button>
                                </div>
                            </div>
                            <div className="is-size-5 mt-6">
                                <p>{`Lesson ${minLessonIndex + 1} - ${maxLessonIndex} of ${lessonCount}`}</p>
                                {newLessonsBtn}
                            </div>
                        </div>
                    </div>
                </section>

                {
                    showContributors &&
                        <div className="modal is-active">
                            <div className="modal-background"></div>
                            <div className="modal-content" style={{background: 'white', borderRadius: '50%'}}>
                                {contributorList}
                            </div>
                            <button 
                                className="modal-close is-large" 
                                aria-label="close"
                                style={{cursor: 'pointer'}}
                                onClick={() => setShowContributors(false)}
                            >
                            </button>
                        </div>
                }

                {
                    showTags &&
                        <div className="modal is-active">
                            <div className="modal-background"></div>
                            <div className="modal-content">
                                {tagList}
                            </div>
                            <button 
                                className="modal-close is-large" 
                                aria-label="close"
                                style={{cursor: 'pointer'}}
                                onClick={() => setShowTags(false)}
                            >
                            </button>
                        </div>
                }

                {
                    showType &&
                        <div className="modal is-active">
                            <div className="modal-background"></div>
                            <div className="modal-content" style={{background: 'white', borderRadius: '50%'}}>
                                {typeList}
                            </div>
                            <button 
                                className="modal-close is-large" 
                                aria-label="close"
                                style={{cursor: 'pointer'}}
                                onClick={() => setShowType(false)}
                            >
                            </button>
                        </div>
                }
            </section>
        </Layout>
    )
}

export default Decolonizing
